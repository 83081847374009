import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Img } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "img"
    }}>{`Img`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Img } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=6790%3A16912'} storybook={'/?path=/story/content-content--playground'} name={'Img'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Img – это компонент, отображающий изображения с подписью.`}</p>
    <p>{`Подпись передается в проп `}<inlineCode parentName="p">{`description`}</inlineCode>{`. Положение подписи определяется пропом `}<inlineCode parentName="p">{`descriptionVariant`}</inlineCode>{` (возможные значения: `}<inlineCode parentName="p">{`bottom`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`).`}</p>
    <p>{`При использовании контентного варианта (`}<inlineCode parentName="p">{`ContentImg`}</inlineCode>{`) добавляются внешние вертикальные отступы.`}</p>
    <Playground __position={1} __code={'<Img\n  description=\"Природа Камчатки\"\n  descriptionVariant=\"bottom\"\n  src=\"http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic\"\n  alt=\"Природа Камчатки\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Img,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Img description="Природа Камчатки" descriptionVariant="bottom" src="http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic" alt="Природа Камчатки" mdxType="Img" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "положение-подписи"
    }}>{`Положение подписи`}</h3>
    <p>{`Положение подписи определяется пропом `}<inlineCode parentName="p">{`descriptionVariant`}</inlineCode>{` (возможные значения: `}<inlineCode parentName="p">{`bottom`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`).`}</p>
    <Playground __position={2} __code={'<Img\n  description=\"Природа Камчатки\"\n  descriptionVariant=\"bottom\"\n  src=\"http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic\"\n  alt=\"Природа Камчатки\"\n  hasMargin\n/>\n<Img\n  description=\"Природа Камчатки\"\n  descriptionVariant=\"right\"\n  src=\"http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic\"\n  alt=\"Природа Камчатки\"\n  hasMargin\n/>'} __scope={{
      props,
      DefaultLayout,
      Img,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Img description="Природа Камчатки" descriptionVariant="bottom" src="http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic" alt="Природа Камчатки" hasMargin mdxType="Img" />
  <Img description="Природа Камчатки" descriptionVariant="right" src="http://i.mycdn.me/i?r=AzEPZsRbOZEKgBhR0XGMT1RknUNatt-Lf0C06ye82WfbQ6aKTM5SRkZCeTgDn6uOyic" alt="Природа Камчатки" hasMargin mdxType="Img" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Img} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на элемент `}<inlineCode parentName="p">{`img`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      